window.toggle_reject_reason = function(e) {
  switch (e.value) {
    case '':
      $('#other').collapse('hide').prop("disabled", true);
      $('#reject-submit').prop('disabled', true);
      break;
    case 'other':
      $('#other').collapse('show').prop("disabled", false);
      $('#reject-submit').prop('disabled', false);
      break;
    default:
      $('#other').collapse('hide').prop("disabled", true);
      $('#reject-submit').prop('disabled', false);
  }
};

window.submit_reject = function() {
  target = '#' + $('#reject-submit').data('target');
  reason = $('#reason').val() != 'other' ? $('#reason').val() : $('#other').val();
  $(target + ' input[name=reason]').val(reason);
  $('#reject_modal').modal('hide');
  $(target).submit();
};

window.submit_hold = function() {
  var reason_el = document.getElementById('hold_reason');

  if (!reason_el.checkValidity()) {
    return false;
  }

  target = '#' + $('#hold-submit').data('target');
  $(target + ' input[name=reason]').val(reason_el.value);
  $('#hold_modal').modal('hide');
  $(target).submit();
};

window.show_hold_submit = function() {
  var reason_el = document.getElementById('hold_reason');

  if (document.getElementById('hold_reason').value.length > 0) {
    document.getElementById('hold-submit').disabled = false;
    reason_el.classList.remove('is-invalid');
    reason_el.classList.add('is-valid');
  } else {
    document.getElementById('hold-submit').disabled = true;
    reason_el.classList.add('is-invalid');
    reason_el.classList.remove('is-valid');
  }
}

window.check_empty = function(button) {
  if (button.attributes['data-supporting-document-count'].value > 0) {
    button.parentNode.submit();
  } else {
    document.getElementById('submit-button').attributes['data-form-id-to-submit'] = button.id
    $('#no-doc-confirmation-modal').modal('show');
  }
};

window.confirm_no_doc = function(button) {
  $('#no-doc-confirmation-modal').modal('hide');
  document.getElementById(button.attributes['data-form-id-to-submit']).parentNode.submit();
}

$(document).on('show.bs.modal', '#dropzone_modal', function(e) {
  Dropzone.autoDiscover = false;
  delete $("#dropzone_area")[0].dropzone
  var dropzone_options = {
    ...window.dropzone_translate,
    'url': e.relatedTarget.attributes['data-url'].value,
    'acceptedFiles': 'image/*,application/pdf',
    'headers': {
      'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
    }
  };
  $("#dropzone_area").dropzone(dropzone_options);
});
$(document).on('hidden.bs.modal', '#dropzone_modal', function(e) {
  Turbolinks.clearCache();
  Turbolinks.visit(window.location.href);
});

$(document).on('show.bs.modal', '#complete_modal', function(e) {
  $("#complete_modal .container-fluid .row").html("");
  $("#complete_task_form").attr('action', $(e.relatedTarget).data('url'));
  files = JSON.parse($("#" + $(e.relatedTarget).data('source')).text());
  files.forEach(function(doc, index) {
    $("#complete_modal .container-fluid .row").append(
      "<div class=\"col-12 col-lg-6\"> \
        <figure class=\"figure\"> \
          <img class=\"img-fluid img-thumbnail figure-img\" src=\"" + doc["url"] + "\"/> \
          <figcaption class=\"figure-caption text-center\"> \
            <input type=\"checkbox\" class=\"form-check-input position-static\" name=\"file_ids[]\" value=\"" + doc["id"] + "\"/> \
          </figcaption> \
        </figure> \
      </div>");
  });
});

$(document).on('show.bs.modal', '#show_file_modal', function(e) {
  // $("#show_file_modal .container-fluid .row").html("");
  template = $("#supporting_documents_preview");

  files = JSON.parse($(e.relatedTarget).find('script').text())
  files.forEach(function(doc, index) {
    supportingDocument = template.clone();
    supportingDocument.removeClass("d-none");
    supportingDocument.find('img').attr('src', doc["url"]);
    supportingDocument.find('p').text(doc["filename"]);
    supportingDocument.find('button.btn-outline-danger').attr('onClick', 'delete_supporting_document(\'' + doc["delete_url"] + '\', \'#supporting_document_' + doc["id"] + '\')');
    supportingDocument.find('.btn-outline-primary').attr('href', doc["download_url"]);
    supportingDocument.attr('id', 'supporting_document_' + doc["id"]);
    supportingDocument.appendTo("#show_file_modal .container-fluid .row");
  });
});

$(document).on('hidden.bs.modal', '#show_file_modal', function(e) {
  if ($(".supporting_document").length > 0) {
    Turbolinks.clearCache();
    Turbolinks.visit(window.location.href);
  };
});

window.delete_supporting_document = function(url, dom_id) {
  $.ajax({ url: url, method: "DELETE", context: $(dom_id) })
    .done(function(data, textStatus, jqXHR) {
      setTimeout(function(dom_id) { $(dom_id).addClass("d-none") }, 150, this);
      $(this).addClass("fade");
    })
    .fail(function(jqXHR, textStatus, errorThrown) {
      $(dom_id).tooltip({ placement: 'right', trigger: 'manual', title: 'Une erreur est survenue. Veuillez recharger la page. An error occurs. Please reload page' }).tooltip('show');
    });
};

window.download_supporting_document = function(url, dom_id) {
  $.ajax({ url: url, method: "GET", context: $(dom_id) })
    .fail(function(jqXHR, textStatus, errorThrown) {
      $(dom_id).tooltip({ placement: 'right', trigger: 'manual', title: 'Une erreur est survenue. Veuillez recharger la page. An error occurs. Please reload page' }).tooltip('show');
    });
};
