// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Basics librairies
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require('moment/dist/moment.js')
import * as bootstrap from 'bootstrap';
import Cookies from 'js-cookie'

// Project specific
require('./documents.js')
require('./pages.js')
require('./tasks.js')

// Stylesheets
import "trix/dist/trix.css"
import '../stylesheets/bootstrap-theme'
import '../stylesheets/mdbootstrap/datatables.min';
import '../stylesheets/actiontext';
import '../stylesheets/documents';
import '../stylesheets/organizations';
import '../stylesheets/pages';
import '../stylesheets/sessions';
import '../stylesheets/tasks';
import "../stylesheets/application";
import "dropzone/dist/min/dropzone.min.css"

window.jQuery = $;
window.$ = $;
window.bootstrap = bootstrap;
window.Dropzone = require('dropzone');
window.Cookies = Cookies;

import Dropzone from "dropzone";
Dropzone.autoDiscover = false;

window.submit_filter = function() {
  $('#filter-form').submit();
};

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../../assets/images', true)
// const imagePath = (name) => images(name, true)
