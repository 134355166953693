window.get_organizations = function(combo) {
  Turbolinks.visit($(combo).val());
}

$(document).on('show.bs.modal', '#document_modal', function (e) {
  $(".carousel-indicators").html("");
  $('.carousel-inner').html("");
  documents = JSON.parse(document.getElementById($('#document_modal').data('urls_id')).innerHTML);
  documents.forEach(function(doc, index) {
    $(".carousel-indicators").append("<li data-bs-target=\"#carousel\" data-bs-slide-to=\"" + index + "\" class= \"" + (index == 0 ? 'active' : '') + "\"></li>")
    switch(doc[1]) {
      case 'image/jpeg', 'image/png':
        // $('.carousel-inner').append("<div class=\"text-center carousel-item" + (index == 0 ? ' active' : '') + "\"><img src='" + doc[0] + "' class='img-fluid'></div>");
        $('.carousel-inner').append(`
          <div class="text-center carousel-item ${index == 0 ? ' active' : ''}">
            <img src="${doc[0]}" class='img-fluid' />
          </div>
        `);
        break;
      case 'application/pdf':
        $('.carousel-inner').append(`
          <div class="text-center carousel-item ${index == 0 ? ' active' : ''}">
            <object data="${doc[0]}" type='application/pdf' style='height: calc(100vh - 140px);width: 100%'>
              <div class="col-8 offset-2">
                <div class="card">
                  <div class="card-header">
                    Prévisulalisation non supportée par votre navigateur / Preview not available on your browser
                  </div>
                  <div class="card-body mt-3">
                    <a class="btn btn-primary" href="${doc[0]}">
                      Cliquer ici pour télécharger le document / Click here to download the document
                    </a>
                  </div>
                </div>
              </div>
            </object>
          </div>
        `);
        break;
    }
  });
});
